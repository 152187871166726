import React, { useState, useEffect } from 'react';
import logecomApi from "../utils/axiosInstance";
import { useHistory } from "react-router-dom";
import Select from 'react-select'
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap"
import { useLocation } from "react-router-dom";
const apiRoot = process.env.REACT_APP_API_ROOT

const Header = () => {
    const getDefaultClient = () => {
        const result = localStorage.getItem("user_gd") === "1" ? localStorage.getItem("selected_client") : localStorage.getItem("id_client")
        return result === undefined || result === "" || result === null ? 0 : parseInt(result)
    }
    const getDefaultBoutique = () => {
        const result = localStorage.getItem("id_boutique" + client)
        return result === undefined || result === "" || result === null ? 0 : parseInt(result)
    }
    const [user, setUser] = useState("");
    const [env, setEnv] = useState("");
    const [clients, setClients] = useState([]);
    const [client, setClient] = useState(getDefaultClient());
    const [boutiques, setBoutiques] = useState([]);
    const [idBoutique, setIdBoutique] = useState(getDefaultBoutique());
    const history = useHistory();
    const location = useLocation();

    const logout = () => {
        logecomApi.post(apiRoot + 'logout', {}).then(() => {
            localStorage.setItem("token", "");
            localStorage.setItem("access_token", "");
            localStorage.setItem("user", "");
            localStorage.setItem("user_gd", 0);
            localStorage.setItem("client_name", "");
            localStorage.setItem("changement_mdp", 0);
            history.push("/login");
        }).catch((error) => {
            console.log(error);
        })
    }

    const formattedClients = clients.map(
        cli => ({ value: cli.Id_Client, label: cli.Nom_Client })
    )
    const formattedBoutiques = boutiques.map(
        boutique => ({ value: boutique.IDBOUTIQUE, label: boutique.NomCommercial !== '' ? boutique.NomCommercial : boutique.Nom })
    )

    const checkValidity = res => {
        return res === undefined || res === "" || res === {} ? [] : res
    }


    const rememberClient = cli => {
        localStorage.setItem("selected_client", cli);
        setClient(cli);
        window.location.reload();
    }
    const rememberBoutique = _idBoutique => {
        localStorage.setItem("id_boutique" + client, _idBoutique);
        setIdBoutique(_idBoutique);
        window.location.reload();
    }

    useEffect(() => {
        if (localStorage.getItem("user_gd") !== "1") {
            return;
        }
        logecomApi.get(apiRoot + 'status/env').then((res) => {
            setEnv(res.data.Base)
        }).catch((error) => {
            console.log(JSON.stringify(error))
        })
    }, []
    );
    useEffect(() => {
        if (client < 1) {
            return;
        }
        logecomApi.get(apiRoot + 'clients/' + client + '/shops').then((res) => {
            if (res.data.length > 1) {
                res.data.unshift({ IDBOUTIQUE: 0, Nom: "Toutes les boutiques", NomCommercial: "Toutes les boutiques" });
            } else {
                localStorage.setItem("id_boutique" + client, res.data[0].IDBOUTIQUE);
            }
            setBoutiques(res.data);
        }).catch((error) => {
            console.log(JSON.stringify(error))
        })
    }, [client]
    );

    useEffect(() => {
        localStorage.setItem("page", "")
        logecomApi.get(apiRoot + 'clients').then((res) => {
            setClients(checkValidity(res.data))
        }).catch((error) => {
            console.log(JSON.stringify(error))
        })
        setUser(localStorage.getItem("user"))
    }, []
    );
    function menudropdown() {
        const { pathname } = location;
        const splitLocation = pathname.split("/");
        const menuname = splitLocation[1];
        return (
            <>
                <Nav>
                    <Nav.Link exact="true" href="/" className={menuname === "" ? "sidenav-link active" : "sidenav-link"} activeclassname="active">
                        Tableau de bord
                    </Nav.Link>
                    <NavDropdown className={['articles', 'lots'].includes(menuname) ? "sidenav-link active" : "sidenav-link"} title="Articles">
                        <NavDropdown.Item href="/articles">Liste des articles</NavDropdown.Item>
                        <NavDropdown.Item href="/lots">Liste des lots</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown className={['pre-reception', 'reception'].includes(menuname) ? "sidenav-link active" : "sidenav-link"} title="Réceptions">
                        <NavDropdown.Item href="/pre-reception">Liste des pré-réceptions</NavDropdown.Item>
                        <NavDropdown.Item href="/reception">Liste des réceptions</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link href="/commande" className={menuname === "commande" ? "sidenav-link active" : "sidenav-link"} activeclassname="active">
                        Commandes
                    </Nav.Link>
                    <Nav.Link href="/sav" className={menuname === "sav" ? "sidenav-link active" : "sidenav-link"} activeclassname="active">
                        SAV
                    </Nav.Link>
                    <Nav.Link href="/discussions" className={menuname === "discussions" ? "sidenav-link active" : "sidenav-link"} activeclassname="active">
                        Discussions
                    </Nav.Link>
                    <Nav.Link href="/factures" className={menuname === "factures" ? "sidenav-link active" : "sidenav-link"} activeclassname="active">
                        Factures
                    </Nav.Link>
                    <Nav.Link href="/adresses" className={menuname === "adresses" ? "sidenav-link active" : "sidenav-link"} activeclassname="active">
                        Adresses
                    </Nav.Link>
                    <NavDropdown className={['tarifconsommables', 'tarif-transporteur'].includes(menuname) ? "sidenav-link active" : "sidenav-link"} title="Tarification" id="basic-nav-dropdown">
                        <NavDropdown.Item href="/tarif-transporteur">Transporteurs</NavDropdown.Item>
                        <NavDropdown.Item href="/tarifconsommables">Consommables</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </>
        );
    }
    if (user !== '') {
        return (
            <div>
                <div className="layout-container">
                    <div className="layout-content">
                        <div className="navbar-wrapper">
                            <Navbar bg="light" expand="lg">
                                <Container className="container" fluid>
                                    <Navbar.Brand href="/"><img src="assets/img/logo-header.png" alt="LoGeCOM" /></Navbar.Brand>
                                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                    <Navbar.Collapse id="responsive-navbar-nav">
                                        <Nav
                                            className="ml-auto"
                                            style={{ maxHeight: '100px' }}
                                            navbarscroll="1"
                                        >
                                            <NavDropdown title={'Bonjour ' + user} id="navbarScrollingDropdown">
                                                {localStorage.getItem("user_gd") === "1" && <NavDropdown.ItemText>{env}</NavDropdown.ItemText>}
                                                {localStorage.getItem("user_gd") === "1" && <NavDropdown.Divider />}
                                                <NavDropdown.Item href="/changemdp">Changer mon mot de passe</NavDropdown.Item>
                                                <NavDropdown.Item onClick={logout}>Déconnexion</NavDropdown.Item>
                                            </NavDropdown>
                                            {localStorage.getItem("user_gd") === "1" &&
                                                <>
                                                    <div style={{ width: '150px', marginLeft: '5px' }}>
                                                        <Select
                                                            options={formattedClients}
                                                            onChange={(event) => { rememberClient(event.value) }}
                                                            placeholder={formattedClients.find(cli => cli.value === client) && formattedClients.find(cli => cli.value === client).label}
                                                            value={formattedClients.find(cli => cli.value === client)}
                                                        >
                                                        </Select>
                                                    </div>
                                                </>
                                            }
                                            {boutiques.length > 1 &&
                                                <div style={{ width: '200px', marginLeft: '5px' }}>
                                                    <Select
                                                        options={formattedBoutiques}
                                                        onChange={(event) => { rememberBoutique(event.value) }}
                                                        placeholder={formattedBoutiques.find(option => option.value === idBoutique) && formattedBoutiques.find(option => option.value === idBoutique).label}
                                                        value={formattedBoutiques.find(option => option.value === idBoutique)}
                                                    >
                                                    </Select>
                                                </div>
                                            }
                                        </Nav>
                                    </Navbar.Collapse>
                                </Container>
                            </Navbar>
                            <div className="container">
                                {menudropdown()}
                                {/*
                                <div id="layout-sidenav" className="layout-sidenav-horizontal sidenav sidenav-horizontal bg-sidenav-theme container-p-x flex-grow-0">
                                    {menudropdown()}
                                </div>
                                        */}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
    else {
        return null;
    }
}

export default Header;
