import React, { useEffect, useState } from 'react';
import logecomApi from "../../utils/axiosInstance";
import { Helmet } from 'react-helmet';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter'
import { Modal, Form, Col, Row, Button, ButtonGroup, ToggleButton } from "react-bootstrap"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import Select from 'react-select'
import { Spinner, InputGroup } from 'react-bootstrap'
import DocumentList from '../Document/DocumentList';
import DiscussionModal from '../Discussion/DiscussionModal';
import LotsbyItem from '../Lots/LotsByItem';
import { downloadCSV, patchFilterFactory, dateIso } from "../../utils/utils";
import OrdersToSendByItem from '../Commandes/OrdersToSendByItem';
import PrereceiptNoReceivedByArticle from '../Pre-Reception/PrereceiptNoReceivedByArticle';
import ArticlePeriode from './ArticlePeriode';
import { useLocation } from 'react-router-dom';
const apiRoot = process.env.REACT_APP_API_ROOT

const Articles = (props) => {
	const client = props.client;
	const [clients, setClients] = useState([]);
	const [loading, setLoading] = useState(false)
	const [data, setData] = useState([]);
	const [codesRegroupement, setCodesRegroupement] = useState([]);
	const [couleurs, setCouleurs] = useState([]);
	const [tailles, setTailles] = useState([]);
	const [marques, setMarques] = useState([]);
	const [famillesArticle, setFamillesArticle] = useState([]);
	const [tags1, setTags1] = useState([]);
	const [tags2, setTags2] = useState([]);
	const [tags3, setTags3] = useState([]);
	const [exportData, setExportData] = useState([]);
	const [watchModalShow, setWatchModalShow] = useState(false);
	const [editModalShow, setEditModalShow] = useState(false);
	const [createModalShow, setCreateModalShow] = useState(false);
	const [selectedID, setSelectedID] = useState(0);
	const [selectedRow, setSelectedRow] = useState({})
	const [defaultStatus, setDefaultStatus] = useState("Actif")
	const [documentList, setDocumentList] = useState(false);
	const [idElementRattache, setIdElementRattache] = useState(0);
	const [titreElement, setTitreElement] = useState("");
	const [discussionVisible, setDiscussionVisible] = useState(false);
	const [afficherArticlesDefecteux, setAfficherArticlesDefecteux] = useState(false);
	const [lotsByItem, setLotsByItemVisible] = useState(false);
	const [ordersToSendByItemVisible, setOrdersToSendByItemVisible] = useState(false);
	const [preReceptByItemVisible, setPreReceptByItemVisible] = useState(false);
	const [libelleTag, setLibelleTag] = useState({});
	const [colonnesOptionnelles, setColonnesOptionnelles] = useState([]);
	const filtreEnum = { Tous: 0, SousSeuilAlerte: 1, RuptureBloquante: 2 };
	let paramsLink = useLocation();
	let filtreParams = paramsLink.state === undefined ? undefined : paramsLink.state.filtre;
	const [filtre, setFiltre] = useState(filtreParams ? parseInt(filtreParams) : filtreEnum.Tous);
	const [dateDebutQteVendue, setDateDebutQteVendue] = useState(() => {
		var d = new Date();
		d.setMonth(d.getMonth() - 1);
		return dateIso(d);
	});
	const [dateFinQteVendue, setDateFinQteVendue] = useState(dateIso(new Date()));
	const [composants, setComposants] = useState([]);

	const checkValidity = res => {
		return res === undefined || res === "" || res === {} ? [] : res
	}
	const tags = (num) => {
		if (num === 1) {
			return tags1;
		}
		else if (num === 2) {
			return tags2;
		} else if (num === 3) {
			return tags3;
		}
	}
	const handleDocumentList = (iditem, codeSKU, libelleArticle1) => {
		if (typeof iditem === 'number') {
			setIdElementRattache(iditem);
			setTitreElement('Article ' + codeSKU + ' - ' + libelleArticle1)
		}
		setDocumentList(true);
	};
	const ouvrirDiscussion = (iditem, codeSKU, libelleArticle1) => {
		setIdElementRattache(iditem);
		setTitreElement('Article ' + codeSKU + ' - ' + libelleArticle1)
		setDiscussionVisible(true);
	};
	const ouvrirLots = (codeSKU, libelleArticle1) => {
		setLotsByItemVisible(true)
		setTitreElement(' de l\'article : ' + codeSKU + ' - ' + libelleArticle1)
	};
	const filteredData = () => {
		if (data === undefined || data === "" || data === {}) {
			return [];
		} else {
			return data.filter(function (row) {
				let affiche = true;
				switch (filtre) {
					case filtreEnum.SousSeuilAlerte:
						affiche = (row.Qte_EnStock < row.Seuil_Alerte);
						break;
					case filtreEnum.RuptureBloquante:
						affiche = (row.QteAEnvoyer > 0 && row.TypeArticle !== 'Kit' && row.Qte_EnStock < row.QteAEnvoyer);
						break;
					default:
						break;
				}
				return affiche;
			})
		}
	}

	const openOrdersToSend = (row) => {
		setSelectedRow(row)
		setOrdersToSendByItemVisible(true);
	}
	const openPreReceipt = (row) => {
		setSelectedRow(row)
		setPreReceptByItemVisible(true);
	}

	const _colonnesOptionnelles = (row) => {
		const cols = [];
		if (row.Marque) {
			cols.push({ titre: "Marque", valeur: row.Marque });
		}
		if (row.Taille) {
			cols.push({ titre: "Taille", valeur: row.Taille });
		}
		if (row.Couleur) {
			cols.push({ titre: "Couleur", valeur: row.Couleur });
		}
		if (row.CodeRegroupement) {
			cols.push({ titre: "Code Regroupement", valeur: row.CodeRegroupement });
		}
		if (row.Tag1) {
			cols.push({ titre: row.LibelleTag1, valeur: row.Tag1 });
		}
		if (row.Tag2) {
			cols.push({ titre: row.LibelleTag2, valeur: row.Tag2 });
		}
		if (row.Tag3) {
			cols.push({ titre: row.LibelleTag3, valeur: row.Tag3 });
		}
		setColonnesOptionnelles(cols);
	}
	useEffect(() => {
		localStorage.setItem("page", "articles")
		logecomApi.get(apiRoot + 'clients').then((res) => {
			setClients(checkValidity(res.data))
		}).catch((error) => {
			console.log(JSON.stringify(error))
		})
	}, []
	);

	const refresh = () => {
		if (dateFinQteVendue < dateDebutQteVendue) {
			alert("Les dates de début et de fin concernant les quantités vendues ne sont pas correctes");
			return;
		}
		setLoading(true)
		logecomApi.get(apiRoot + 'clients/' + client + '/items/filter_status?dateDebutQteVendue=\'' + dateDebutQteVendue + '\'&dateFinQteVendue=\'' + dateFinQteVendue + '\'').then((res) => {
			setData(checkValidity(res.data))
			setLoading(false)
			if (res.data[0]) {
				setLibelleTag({
					LibelleTag1: res.data[0].LibelleTag1,
					LibelleTag2: res.data[0].LibelleTag2,
					LibelleTag3: res.data[0].LibelleTag3
				});
				let afficherDefectueux = false;
				res.data.forEach(element => {
					if (element.Qte_NonConforme && element.Qte_NonConforme > 0) {
						afficherDefectueux = true;
					}
				});
				setAfficherArticlesDefecteux(afficherDefectueux);
			}
			setLoading(false)
			setEditModalShow(false)
			setCreateModalShow(false)
			window.history.replaceState({}, document.title)
		}).catch((error) => {
			console.log(JSON.stringify(error))
			setLoading(false)
		})
	}

	useEffect(() => {
		if (!client || parseInt(client) === 0) {
			return;
		}
		refresh();
		logecomApi.get(apiRoot + 'clients/' + client + '/codesregroupement').then((res) => {
			setCodesRegroupement(checkValidity(res.data))
		}).catch((error) => {
			console.log(JSON.stringify(error))
			setLoading(false)
		})
		logecomApi.get(apiRoot + 'clients/' + client + '/tags1').then((res) => {
			setTags1(checkValidity(res.data))
		}).catch((error) => {
			console.log(JSON.stringify(error))
			setLoading(false)
		})
		logecomApi.get(apiRoot + 'clients/' + client + '/tags2').then((res) => {
			setTags2(checkValidity(res.data))
		}).catch((error) => {
			console.log(JSON.stringify(error))
			setLoading(false)
		})
		logecomApi.get(apiRoot + 'clients/' + client + '/tags3').then((res) => {
			setTags3(checkValidity(res.data))
		}).catch((error) => {
			console.log(JSON.stringify(error))
			setLoading(false)
		})
		logecomApi.get(apiRoot + 'clients/' + client + '/colors').then((res) => {
			setCouleurs(checkValidity(res.data))
		}).catch((error) => {
			console.log(JSON.stringify(error))
			setLoading(false)
		})
		logecomApi.get(apiRoot + 'clients/' + client + '/sizes').then((res) => {
			setTailles(checkValidity(res.data))
		}).catch((error) => {
			console.log(JSON.stringify(error))
			setLoading(false)
		})
		logecomApi.get(apiRoot + 'clients/' + client + '/brands').then((res) => {
			setMarques(checkValidity(res.data))
		}).catch((error) => {
			console.log(JSON.stringify(error))
			setLoading(false)
		})
		logecomApi.get(apiRoot + 'clients/' + client + '/item_family').then((res) => {
			res.data.unshift({ IDFAMILLE_ARTICLE: 0, LibelleFamille: "Sans famille" });
			setFamillesArticle(checkValidity(res.data))
		}).catch((error) => {
			console.log(JSON.stringify(error))
			setLoading(false)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [client, clients]
	);
	const openWatchModal = (row) => {
		getArticle(row.Id_Article).then((data) => {
			_colonnesOptionnelles(data);
			setSelectedRow(data)
			setWatchModalShow(true)
		}).catch((error) => {
			console.log(JSON.stringify(error))
		})
	}

	const getArticle = async (idarticle) => {
		return await logecomApi.get(apiRoot + 'items/' + idarticle).then((res) => {
			getComposants(idarticle).then((composants) => {
				setComposants(checkValidity(composants));
			}).catch((error) => {
				console.log(JSON.stringify(error))
			})

			let checkedData = checkValidity(res.data)
			if (checkedData.length > 0) {
				return checkedData[0];
			} else {
				return checkedData;
			}
		}).catch((error) => {
			console.log(JSON.stringify(error));
			throw new Error(error);
		})
	}

	const getComposants = async (idarticle) => {
		return await logecomApi.get(apiRoot + 'items/' + idarticle + '/components').then((res) => {
			let checkedData = checkValidity(res.data)
			return checkedData;
		}).catch((error) => {
			console.log(JSON.stringify(error));
			throw new Error(error);
		})
	}

	const openEditModal = (row) => {
		getArticle(row.Id_Article).then((data) => {
			setSelectedID(row.Id_Article);
			setSelectedRow(data);
			setEditModalShow(true);
		}).catch((error) => {
			console.log(JSON.stringify(error))
		})
	}

	const openCreateModal = () => {
		setSelectedRow({ CodeSKU: "", LibelleArticle1: "", LibelleArticle2: "", PoidsArticle: "0.000", VolumeArticle: "0.000", LongueurArticle: "0.000", LargeurArticle: "0.000", HauteurArticle: "0.000", AlerteQuantiteMin: "0", QuantiteParCarton: "0", QuantiteParPalette: "0", IdClient: client, LibelleTag1: "", LibelleTag2: "", LibelleTag3: "", CodeRegroupement: "", Taille: "", Couleur: "", Marque: "", IDFAMILLE_ARTICLE: 0, CodeBarrePicking: "", Tag1: "", Tag2: "", Tag3: "" })
		setCreateModalShow(true)
	}

	const isIE10Mode = document['documentMode'] === 10


	const myTableFilterFactory = patchFilterFactory(filterFactory, (filteredData) => {
		setExportData(prevData => {
			if (JSON.stringify(prevData) !== JSON.stringify(filteredData)) {
				return filteredData
			}
			return prevData
		})
	})

	const controlsFormatter = (_, row) => {
		let coulBtnDiscussion = '#fff';
		let titleDiscussion = 'Discussion fermée';
		if (row.nbMessagesNonLu > 0) {
			coulBtnDiscussion = 'red';
			titleDiscussion = 'Discussion avec des messages non lus';
		} else if (row.nbDiscussionOuverte > 0) {
			coulBtnDiscussion = 'green';
			titleDiscussion = 'Discussion ouverte';
		}
		return (
			< div className="btn-group" >
				<button type="button" className={row.Qte_EnStock < row.Seuil_Alerte ? "btncommande btn-sm btn-danger" : "btncommande btn-sm btn-primary"} style={{ marginRight: '5px' }} onClick={() => openWatchModal(row)}>
					<i className="ion ion-md-eye d-block"></i>
				</button>
				<button type="button" className={row.Qte_EnStock < row.Seuil_Alerte ? "btncommande btn-sm btn-danger" : "btncommande btn-sm btn-primary"} style={{ marginRight: '5px' }} onClick={() => openEditModal(row)}>
					<i className="ion ion-md-create d-block"></i>
				</button>
				{
					row.Supprimable === 1 ?
						<React.Fragment>
							<button type="button" className={row.Qte_EnStock < row.Seuil_Alerte ? "btncommande btn-sm btn-danger" : "btncommande btn-sm btn-primary"} style={{ marginRight: '5px' }} onClick={() => deleteRow(row)}>
								<i className="ion ion-md-trash d-block"></i>
							</button>
						</React.Fragment> : null
				}
				{
					row.docExiste === 1 ?
						<button type="button" className="btncommande btn-sm btn-primary" style={{ marginRight: '5px' }} onClick={() => handleDocumentList(row.Id_Article, row.SKU, row.Lib_Article1)}>
							<i className="ion ion-md-document d-block"></i>
						</button> : null
				}
				{
					row.nbMessages > 0 ?
						<button type="button" className="btncommande btn-sm btn-primary" style={{ marginRight: '5px', color: coulBtnDiscussion }} title={titleDiscussion} onClick={() => ouvrirDiscussion(row.Id_Article, row.SKU, row.Lib_Article1)}>
							<i className="ion ion-md-chatbubbles d-block"></i>
						</button> : null
				}
			</div >
		)
	}


	const headerWidth = (width) => (colum, colIndex) => {
		return { width: width + 'px', fontSize: '12.5px', lineHeight: '14px' };
	}


	// La 1ère valeur est l'en-tête de colonne, la 2ème le nom de la propriété renvoyée par l'API, la 3ème la largeur de la colonne en pixels  
	const columns =
		[["", "", 125, false],
		["ID", "Id_Article", 0, false], ["Supprimable", "Supprimable", 0, false], ["SKU", "SKU", 180, true], ["Libellé", "Lib_Article1", 200, true],
		["Libellé complémentaire", "Lib_Article2", 150, true], ["Famille", "Lib_Famille", 110, true], ["Statut", "Statut", 90, true],
		["Tag1", "Tag1", 130, true], ["Tag2", "Tag2", 130, true], ["Tag3", "Tag3", 130, true],
		["Qté vendue", "QteVenduePeriode", 60, true], ["Qté en stock", "Qte_EnStock", 60, true], ["Qté défecteux", "Qte_NonConforme", 60, true],
		["Qté à expédier", "QteAEnvoyer", 60, true], ["Qté à recevoir", "QuantiteARecevoir", 60, true],
		["Qté prévisionnelle disponible", "QuantitePrevisionnelleDisponible", 90, true], ["Qté par palette", "Qte_ParPalette", 60, true],
		["Type", "TypeArticle", 90, true],
		["Lot", "GestionDesLots", 90, true],
		["Qté Seuil Alerte", "Seuil_Alerte", 60, true],
		["Code-barres", "CodeBarrePicking", 60, true]
		]
			.map(elt => {
				let result = { text: elt[0], dataField: elt[1], exporter: elt[3], headerStyle: headerWidth(elt[2]), filter: textFilter({ placeholder: elt[0] }), sort: true }
				if (result.dataField === "") {
					result.isDummyField = true
					result.CSVExport = false
					result.formatter = controlsFormatter
					result.sort = false
					result.filter = undefined
				}
				result.style = { verticalAlign: 'middle' }
				if (['Id_Article', 'Supprimable', 'CodeBarrePicking'].includes(result.dataField)) {
					result.hidden = true
				}
				if ('Qte_NonConforme' === result.dataField) {
					result.hidden = !afficherArticlesDefecteux
					result.CSVExport = afficherArticlesDefecteux
				}
				if (["QteVenduePeriode", "Qte_EnStock", "QteAEnvoyer", "QuantiteARecevoir", "QuantitePrevisionnelleDisponible", "Qte_ParPalette", "QteReservee", "Seuil_Alerte", 'Qte_NonConforme'].includes(result.dataField)) {
					result.style = { textAlign: 'right', verticalAlign: 'middle' };
					result.formatter = (cell, row) => {
						if (cell === 0) {
							return ('')
						} else if (result.dataField === "QteAEnvoyer") {
							return (<Button variant="link" title='Liste des commandes pour cet article' onClick={() => openOrdersToSend(row)}>{cell}</Button>)
						} else if (result.dataField === "QuantiteARecevoir") {
							return (<Button variant="link" title='Liste des pré-réceptions pour cet article' onClick={() => openPreReceipt(row)}>{cell}</Button>)
						} else if (result.dataField === "Qte_NonConforme") {
							return (<div style={{ color: 'red' }}>{cell}</div>)
						} else {
							return (cell)
						}
					};
				};
				if (['GestionDesLots'].includes(result.dataField)) {
					result.style = { textAlign: 'center', verticalAlign: 'middle' };
				}

				result.headerTitle = (cell, row, rowIndex, colIndex) => {
					if ('Qte_EnStock' === result.dataField) return 'Quantité en stock physique, déduction faite des quantités en cours de préparation de commande ou de personnalisation (qté réservées)';
					else if ('QteReservee' === result.dataField) return 'Quantité en cours de préparation de commande ou de personnalisation';
					else if ('QteAEnvoyer' === result.dataField) return 'Quantité totale articles à expédier sur cet article, tous confondu (non préparé, en cours de picking, en cours de personnalisation)';
					else if ('QuantitePrevisionnelleDisponible' === result.dataField) return 'qté stock + qté réservée + qté à recevoir - qté à expédier';
				};

				[1, 2, 3].forEach(num => {
					if (result.dataField === 'Tag' + num) {
						if (data && data.length > 0) {
							if (data[0]['LibelleTag' + num] === '') {
								result.hidden = true
							} else {
								result.text = data[0]['LibelleTag' + num];
							}
						}
					}
				});

				if (result.dataField === "TypeArticle") {
					var options = {};
					data.forEach(row => options[row.TypeArticle] = row.TypeArticle);
					result.formatter = cell => options[cell];
					result.filter = selectFilter({
						options: options,
						className: 'custom-select',
						placeholder: 'Tous'
					})
					result.hidden = (Object.keys(options).length < 2);
				}
				if (result.dataField === "Statut") {
					var optionsStatut = {};
					data.forEach(row => optionsStatut[row.Statut] = row.Statut);
					result.filter = selectFilter({
						options: optionsStatut,
						className: 'custom-select',
						defaultValue: defaultStatus,
						placeholder: 'Tous'
					})
				}
				if (result.dataField === "GestionDesLots") {
					var types = [
						{ value: true, label: <i className="ion ion-md-checkmark-circle d-block"></i> },
						{ value: false, label: '' },
					];
					result.formatter = (cell) => {
						let type = types.find(type => type.value === cell);
						if (type) {
							return type.label;
						} else {
							return null;
						}
					};
					result.filter = selectFilter({
						options: {
							true: 'Oui',
							false: 'Non',
						},
						className: 'custom-select',
						placeholder: 'Tous'
					})
				}
				return result
			})

	// Change le CSS d'une ligne en fonction de l'index
	const rowStyle = (row, rowIndex) => {

		if (rowIndex % 2 === 1) {
			return { backgroundColor: '#dddddd' };
		}
	};

	const deleteRow = (row) => {
		logecomApi.delete(apiRoot + 'items/' + row.Id_Article).then(res => {
			setData(data.filter(elt => elt.Id_Article !== row.Id_Article))
		})
			.catch((error) => {
				alert(error.response.data.fault.detail)
			})
	}


	const editRow = (key, value) => {
		if (value !== "") {
			const intValues = ["AlerteQuantiteMin", "QuantiteParCarton", "QuantiteParPalette", "LimiteAvantExpirationLot"]
			const realValues = ["PoidsArticle", "VolumeArticle", "HauteurArticle", "LargeurArticle", "LongueurArticle"]
			const intReg = /^\d*$/
			const realReg = /^\d+([.|,]\d{0,3}){0,1}$/
			if (intValues.includes(key)) {
				value = value === "" ? 0 : value

				if (!intReg.test(value)) {
					value = selectedRow[key]
				}
				else {
					value = parseInt(value).toString()
				}
			}
			if (realValues.includes(key)) {
				if (!realReg.test(value)) {
					value = selectedRow[key]
				}

			}
		}
		let tempRow = JSON.parse(JSON.stringify(selectedRow)) // Important, sans copie profonde, React ne déclenchera pas de rafraîchissement

		tempRow[key] = value
		setSelectedRow(tempRow)
	}

	const putSelectedRow = () => {
		const checkSep = elt => {
			if (elt === "") return "0";
			let str = elt.toString();
			let len = str.length;
			let lastChar = str.charAt(len - 1);
			return lastChar === "," || lastChar === "." ? str.substring(0, len - 1) : elt
		};
		const rowToSend = {
			PoidsArticle: checkSep(selectedRow.PoidsArticle), VolumeArticle: checkSep(selectedRow.VolumeArticle), LongueurArticle: checkSep(selectedRow.LongueurArticle),
			LargeurArticle: checkSep(selectedRow.LargeurArticle), HauteurArticle: checkSep(selectedRow.HauteurArticle), AlerteQuantiteMin: checkSep(selectedRow.AlerteQuantiteMin),
			QuantiteParCarton: checkSep(selectedRow.QuantiteParCarton), QuantiteParPalette: checkSep(selectedRow.QuantiteParPalette),
			Tag1: selectedRow.Tag1, Tag2: selectedRow.Tag2, Tag3: selectedRow.Tag3,
			CodeRegroupement: selectedRow.CodeRegroupement, Taille: selectedRow.Taille, Couleur: selectedRow.Couleur, Marque: selectedRow.Marque,
			IDFAMILLE_ARTICLE: selectedRow.IDFAMILLE_ARTICLE, CodeBarrePicking: selectedRow.CodeBarrePicking, CodeDouanier: selectedRow.CodeDouanier,
			LibelleArticle1: selectedRow.LibelleArticle1, LibelleArticle2: selectedRow.LibelleArticle2, GestionDesLots: selectedRow.GestionDesLots,
			LimiteAvantExpirationLot: selectedRow.LimiteAvantExpirationLot
		}

		logecomApi.put(apiRoot + 'items/' + selectedID, rowToSend).then(() => {
			refresh();
		}).then((res) => {
			setEditModalShow(false)
		}).catch((error) => {
			alert(error.response.data.fault.detail)
		})
	}

	const createSelectedRow = () => {
		const checkSep = elt => { let str = elt.toString(); let len = str.length; let lastChar = str.charAt(len - 1); return lastChar === "," || lastChar === "." ? str.substring(0, len - 1) : elt };

		const rowToSend = {
			CodeSKU: selectedRow.CodeSKU, LibelleArticle1: selectedRow.LibelleArticle1, LibelleArticle2: selectedRow.LibelleArticle2, LibelleFamille: selectedRow.LibelleFamille,
			PoidsArticle: checkSep(selectedRow.PoidsArticle), VolumeArticle: checkSep(selectedRow.VolumeArticle), LongueurArticle: checkSep(selectedRow.LongueurArticle),
			LargeurArticle: checkSep(selectedRow.LargeurArticle), HauteurArticle: checkSep(selectedRow.HauteurArticle),
			AlerteQuantiteMin: checkSep(selectedRow.AlerteQuantiteMin), QuantiteParCarton: checkSep(selectedRow.QuantiteParCarton),
			QuantiteParPalette: checkSep(selectedRow.QuantiteParPalette), IdClient: client, Statut: 2,
			Tag1: selectedRow.Tag1, Tag2: selectedRow.Tag2, Tag3: selectedRow.Tag3,
			CodeRegroupement: selectedRow.CodeRegroupement, Taille: selectedRow.Taille, Couleur: selectedRow.Couleur, Marque: selectedRow.Marque,
			IDFAMILLE_ARTICLE: selectedRow.IDFAMILLE_ARTICLE, CodeBarrePicking: selectedRow.CodeBarrePicking, CodeDouanier: selectedRow.CodeDouanier
		}
		logecomApi.post(apiRoot + 'items', rowToSend).then(() => {
			setLoading(true)
			refresh();
			setDefaultStatus('Illégal')
		}).then((res) => {
			setCreateModalShow(false)
		}).catch((error) => {
			alert(error.response.data.fault.detail)
		})
	}
	if (clients.length > 0) {
		return (
			<React.Fragment>
				<Helmet><title>LOGeCOM - Articles</title>

				</Helmet>
				<div className="layout-wrapper layout-1 layout-without-sidenav">
					<div className="layout-inner">

						<div className="layout-container">
							<div className="layout-content">

								<div className="container flex-grow-1 mycontainer-p-y unbox">
									{loading ?
										<div style={{ textAlign: 'center' }}>
											<Spinner animation="border" role="status" style={{ color: 'blue' }} size="xl">
												<span className="sr-only">Loading...</span>
											</Spinner></div> :
										<div>


											{isIE10Mode && <div className="alert alert-danger">
												<strong>react-bootstrap-table2</strong> doesn't support Internet Explorer 10
											</div>}

											{!isIE10Mode && <React.Fragment>

												<ToolkitProvider
													keyField="Id_Article"
													data={filteredData()}
													columns={columns}
													bootstrap4
													search
													columnToggle
												>
													{props => (
														<div>
															<Row style={{ marginBottom: '20px' }}>
																<Col>
																	<div className="header-controlesX">
																		<Row>
																			<Col sm={3}><span className="titre-section font-weight-bold">Articles</span></Col>
																			<Col>
																				<InputGroup>
																					<div style={{ width: '95px' }}></div>
																					<ArticlePeriode
																						dateDebutQteVendue={dateDebutQteVendue}
																						dateFinQteVendue={dateFinQteVendue}
																						setDateFinQteVendue={setDateFinQteVendue}
																						setDateDebutQteVendue={setDateDebutQteVendue}
																						refresh={refresh}
																					>
																					</ArticlePeriode>
																					<button type="button" className="btn btn-md btn-primary" style={{ marginLeft: '15px', marginRight: '5px', height: '38px' }} onClick={openCreateModal}>
																						<i className="ion ion-md-add-circle d-block"></i>
																					</button>

																					<Button variant="default" className="blue" onClick={() => { downloadCSV(exportData, columns, 'Articles', client) }}>Export CSV</Button>

																					<ButtonGroup style={{ marginLeft: '5px' }} toggle className="blue">

																						<ToggleButton
																							type="checkbox"
																							variant="secondary"
																							checked={filtre === filtreEnum.Tous}
																							value="1"
																							onChange={(e) => setFiltre(filtreEnum.Tous)}
																						>
																							Tous
																						</ToggleButton>
																						<ToggleButton
																							type="checkbox"
																							variant="secondary"
																							checked={filtre === filtreEnum.SousSeuilAlerte}
																							value="1"
																							onChange={(e) => setFiltre(filtreEnum.SousSeuilAlerte)}
																						>
																							Sous seuil alerte
																						</ToggleButton>
																						<ToggleButton
																							type="checkbox"
																							variant="secondary"
																							checked={filtre === filtreEnum.RuptureBloquante}
																							value="1"
																							onChange={(e) => setFiltre(filtreEnum.RuptureBloquante)}
																						>
																							Rupture bloquante
																						</ToggleButton>
																					</ButtonGroup>
																				</InputGroup>
																			</Col>
																		</Row>
																	</div>
																</Col>
															</Row>
															<BootstrapTable
																{...props.baseProps}
																rowStyle={rowStyle}
																wrapperClasses="table-responsive"
																pagination={paginationFactory({ sizePerPageList: [20, 50, 100, 250], defaultPageSize: 50 })}
																filter={myTableFilterFactory()}
															/>
														</div>
													)}
												</ToolkitProvider>


											</React.Fragment>}
											<p>Les contrôles en <strong style={{ color: '#d03333' }} >rouge</strong> indiquent les articles dont la quantité en stock est inférieure au seuil d'alerte. </p>

										</div>}

									<OrdersToSendByItem
										isModalVisible={ordersToSendByItemVisible}
										client={client}
										article={selectedRow}
										fermer={() => setOrdersToSendByItemVisible(false)}
									/>
									<PrereceiptNoReceivedByArticle
										isModalVisible={preReceptByItemVisible}
										client={client}
										article={selectedRow}
										fermer={() => setPreReceptByItemVisible(false)}
									/>

									<DocumentList
										isModalVisible={documentList}
										elementRattache='Item'
										idElementRattache={idElementRattache}
										client={client}
										titre={titreElement}
										handleDocumentList={() => setDocumentList(!documentList)}
									/>
									<DiscussionModal
										isModalVisible={discussionVisible}
										elementRattache='Item'
										idElementRattache={idElementRattache}
										client={client}
										titre={titreElement}
										fermerDiscussion={() => setDiscussionVisible(false)}
									/>

									<LotsbyItem
										isModalVisible={lotsByItem}
										fermer={() => setLotsByItemVisible(false)}
										client={client}
										articleId={selectedRow.Id_Article}
										titre={titreElement}
										modeSelection={false}
									/>

									<Modal show={watchModalShow} backdrop="static" onHide={() => { setWatchModalShow(false) }} centered size="lg">
										<Modal.Header closeButton>
											<Modal.Title as="h4">
												Fiche article <br />
											</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<div className="section-form">
												<Row className="mb-2">
													<Col sm={8}>
														<strong>Statut :&nbsp; </strong>
														{selectedRow.Statut}
													</Col>
													<Col sm={4}>
														<strong>Type :&nbsp; </strong>
														{selectedRow.TypeArticle}
													</Col>
												</Row>
												<Row className="mb-2">
													<Col sm={8}>
														<strong>Code SKU :&nbsp;</strong>
														{selectedRow.CodeSKU}
													</Col>
													<Col sm={4}>
														<strong>Libellé :&nbsp;</strong>
														{selectedRow.LibelleArticle1}
													</Col>
												</Row>
												<Row className="mb-2">
													<Col sm={8}>
														<strong>Libellé complémentaire :&nbsp;</strong>
														{selectedRow.LibelleArticle2}
													</Col>
													<Col sm={4}>
														<strong>Famille :&nbsp;</strong>
														{selectedRow.LibelleFamille}
													</Col>
												</Row>
												<Row className="mb-2">
													<Col sm={8}>
														<strong>Code douanier :&nbsp;</strong>
														{selectedRow.CodeDouanier}
													</Col>
													<Col sm={4}>
														<strong>Type :&nbsp;</strong>
														{selectedRow.TypeArticle}
													</Col>
												</Row>

												{selectedRow.CodeBarrePicking && selectedRow.CodeBarrePicking.substring(0, 4) !== client.toString().padStart(4, '0') &&
													<Row className="mb-2">
														<Col sm={8}>
															<strong>Code-barres EAN :&nbsp;</strong>
															{selectedRow.CodeBarrePicking}
														</Col>
													</Row>
												}
											</div>
											<div className="section-form">
												<Row className="mb-2">
													<Col sm={4}>
														<strong>Poids :&nbsp;</strong>
														{selectedRow.PoidsArticle} kg
													</Col>
													<Col sm={4}>
														<strong>Volume :&nbsp;</strong>
														{selectedRow.VolumeArticle} m<exp>3</exp>
													</Col>
												</Row>
												<Row className="mb-2">
													<Col sm={4}>
														<strong>Hauteur :&nbsp;</strong>
														{selectedRow.HauteurArticle} cm
													</Col>
													<Col sm={4}>
														<strong>Largeur :&nbsp;</strong>
														{selectedRow.LargeurArticle} cm
													</Col>
													<Col sm={4}>
														<strong>Longueur :&nbsp;</strong>
														{selectedRow.LongueurArticle} cm
													</Col>
												</Row>
											</div>
											<div className="section-form">
												<Row className="mb-2">
													<Col sm={12}>
														<strong>Alerte quantité minimum :&nbsp;</strong>
														{selectedRow.AlerteQuantiteMin}
													</Col>
												</Row>
												<Row className="mb-2">
													<Col sm={6}>
														<strong>Quantité par carton :&nbsp;</strong>
														{selectedRow.QuantiteParCarton}
													</Col>
													<Col sm={6}>
														<strong>Quantité par palette :&nbsp;</strong>
														{selectedRow.QuantiteParPalette}
													</Col>
												</Row>
												<Row className="mb-2">
													<Col sm={6}>
														<strong>Quantité vendue :&nbsp;</strong>
														{selectedRow.QuantiteVendue}
													</Col>
													<Col sm={6}>
														<strong>Quantité en stock :&nbsp;</strong>
														{selectedRow.QuantiteEnStock}
													</Col>
												</Row>
												{composants.map(composant => {
													return (
														<Row className="mb-2">
															<Col sm={6}>
																<strong>{"Composant " + composant.NumComposant + " : "}&nbsp;</strong>
																{composant.CodeSKU + ' ' + composant.LibelleArticle1 + ' ' + composant.LibelleArticle2}
															</Col>
															<Col sm={6}>
																<strong>Qté :&nbsp;</strong>
																{composant.Quantite}
															</Col>
														</Row>
													)
												}
												)}
												{[0, 2, 4, 6, 8, 10].map(indice => {
													if (colonnesOptionnelles[indice])
														return (
															<Row className="mb-2">
																<Col sm={6}>
																	<strong>{colonnesOptionnelles[indice].titre + " : "}&nbsp;</strong>
																	{colonnesOptionnelles[indice].valeur}
																</Col>
																{colonnesOptionnelles[indice + 1] ?
																	<Col sm={6}>
																		<strong>{colonnesOptionnelles[indice + 1].titre + " : "}&nbsp;</strong>
																		{colonnesOptionnelles[indice + 1].valeur}
																	</Col>
																	: null
																}
															</Row>
														)
													else
														return null;
												}
												)}
											</div>
											{selectedRow.GestionDesLots &&
												<div className="section-form">
													<Row className="mb-2">
														<Col sm={8}>
															<span data-toggle="tooltip" title="Permet de définir la date maximale d'envoi du lot en fonction de la date d'expiration">
																<strong>
																	Lot : Nombre de jours de durabilité minimale (DDM) :
																</strong>
															</span>
															<span> {selectedRow.LimiteAvantExpirationLot}</span>
														</Col>
													</Row>
												</div>
											}
										</Modal.Body>
										<Modal.Footer>
											{selectedRow.GestionDesLots && <Button variant="default" className="blue" onClick={() => { ouvrirLots(selectedRow.CodeSKU, selectedRow.LibelleArticle1) }}>Lots</Button>}
											<Button variant="default" className="blue" onClick={() => { ouvrirDiscussion(selectedRow.Id_Article, selectedRow.CodeSKU, selectedRow.LibelleArticle1) }}>Discussions</Button>
											<Button variant="default" className="blue" onClick={() => { handleDocumentList(selectedRow.Id_Article, selectedRow.CodeSKU, selectedRow.LibelleArticle1) }}>Documents</Button>
											<Button variant="default" className="blue" onClick={() => { setWatchModalShow(false) }}>Fermer</Button>
										</Modal.Footer>
									</Modal>

									<Modal show={editModalShow} backdrop="static" onHide={() => { setEditModalShow(false) }} size="lg">
										<Modal.Header closeButton>
											<Modal.Title as="h5">
												Fiche article <br />
											</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<div className="section-form">
												<Row className="mb-2">
													<Col sm={6}>
														<strong>Statut :&nbsp; </strong>
														{selectedRow.Statut}
													</Col>
													<Col sm={6}>
														<strong>Type :&nbsp; </strong>
														{selectedRow.TypeArticle}
													</Col>
												</Row>
												<Row className="mb-2">
													<Col sm={6}>
														<strong>Code SKU :&nbsp;</strong>
														{selectedRow.CodeSKU}
													</Col>
													{selectedRow.Statut === 'Illégal' ?
														<>
															<Col sm={2}>
																<strong>Libellé :&nbsp;</strong>
															</Col>
															<Col sm={4}>
																<Form.Control value={selectedRow.LibelleArticle1} type="text" maxLength="80" placeholder="Libellé" onChange={e => editRow("LibelleArticle1", e.target.value)} />
															</Col>
														</>
														:
														<Col sm={6}>
															<strong>Libellé :&nbsp;</strong>
															{selectedRow.LibelleArticle1}
														</Col>
													}
												</Row>
												<Row className="mb-2">
													{selectedRow.Statut === 'Illégal' ?
														<>
															<Col sm={2}>
																<strong>Lib. compl. :&nbsp;</strong>
															</Col>
															<Col sm={4}>
																<Form.Control value={selectedRow.LibelleArticle2} type="text" maxLength="80" placeholder="Libellé complémentaire" onChange={e => editRow("LibelleArticle2", e.target.value)} />
															</Col>
														</>
														:
														<Col sm={6}>
															<strong>Libellé complémentaire :&nbsp;</strong>
															{selectedRow.LibelleArticle2}
														</Col>
													}
													<Col sm={6}>
														<strong>Famille :&nbsp;</strong>
														{selectedRow.LibelleFamille}
													</Col>
												</Row>
												<Row className="mb-2">
													{selectedRow.Statut === 'Illégal' ?
														<>
															<Col sm={2}>
																<strong>Code-barres :&nbsp;</strong>
															</Col>
															<Col sm={4}>
																<Form.Control value={selectedRow.CodeBarrePicking} type="text" placeholder="Libellé complémentaire" onChange={e => editRow("CodeBarrePicking", e.target.value)} />
															</Col>
														</>
														:
														selectedRow.CodeBarrePicking && selectedRow.CodeBarrePicking.substring(0, 4) !== client.toString().padStart(4, '0') && <Col sm={6}>
															<strong>Code-barres EAN :&nbsp;</strong>
															{selectedRow.CodeBarrePicking}
														</Col>
													}
													<Col sm={3}>
														<strong>
															Code douanier :
														</strong>
													</Col>
													<Col sm={3}>
														<InputGroup className="">
															<Form.Control type="text" value={selectedRow.CodeDouanier} onChange={e => editRow("CodeDouanier", e.target.value)} />
														</InputGroup>
													</Col>
													<Col sm={6}>
														<strong>Type :&nbsp;</strong>
														{selectedRow.TypeArticle}
													</Col>

												</Row>
											</div>
											<div className="section-form">
												<Row className="mb-4">
													<Col sm={4}>
														<strong>
															Poids
														</strong>
														<InputGroup className="">
															<Form.Control type="text" value={selectedRow.PoidsArticle} placeholder="0.000" onChange={e => editRow("PoidsArticle", e.target.value)} />
															<InputGroup.Append>
																<InputGroup.Text>kg</InputGroup.Text>
															</InputGroup.Append>
														</InputGroup>
													</Col>
													<Col sm={4}>
														<strong>
															Volume
														</strong>
														<InputGroup className="">
															<Form.Control type="text" value={selectedRow.VolumeArticle} placeholder="0.000" onChange={e => editRow("VolumeArticle", e.target.value)} />
															<InputGroup.Append>
																<InputGroup.Text>m<sup>3</sup></InputGroup.Text>
															</InputGroup.Append>
														</InputGroup>
													</Col>
												</Row>
												<Row className="mb-2">
													<Col sm={4}>
														<strong>
															Hauteur
														</strong>
														<InputGroup className="">
															<Form.Control type="text" value={selectedRow.HauteurArticle} placeholder="0.000" onChange={e => editRow("HauteurArticle", e.target.value)} />
															<InputGroup.Append>
																<InputGroup.Text>cm</InputGroup.Text>
															</InputGroup.Append>
														</InputGroup>
													</Col>
													<Col sm={4}>
														<strong>
															Largeur
														</strong>
														<InputGroup className="">
															<Form.Control type="text" value={selectedRow.LargeurArticle} placeholder="0.000" onChange={e => editRow("LargeurArticle", e.target.value)} />
															<InputGroup.Append>
																<InputGroup.Text>cm</InputGroup.Text>
															</InputGroup.Append>
														</InputGroup>
													</Col>
													<Col sm={4}>
														<strong>
															Longueur
														</strong>
														<InputGroup className="">
															<Form.Control type="text" value={selectedRow.LongueurArticle} placeholder="0.000" onChange={e => editRow("LongueurArticle", e.target.value)} />
															<InputGroup.Append>
																<InputGroup.Text>cm</InputGroup.Text>
															</InputGroup.Append>
														</InputGroup>
													</Col>
												</Row>
											</div>
											<div className="section-form">
												<Row className="mb-2">
													<Col sm={4}>
														<strong>
															Alerte quantité minimum :
														</strong>
													</Col>
													<Col sm={2}>
														<Form.Control type="text" value={selectedRow.AlerteQuantiteMin} onChange={e => editRow("AlerteQuantiteMin", e.target.value)} />
													</Col>
												</Row>
												<Row className="mb-2">
													<Col sm={4}>
														<strong>
															Quantité par carton :
														</strong>
													</Col>
													<Col sm={2}>
														<Form.Control type="text" value={selectedRow.QuantiteParCarton} onChange={e => editRow("QuantiteParCarton", e.target.value)} />
													</Col>
													<Col sm={4}>
														<strong>
															Quantité par palette :
														</strong>
													</Col>
													<Col sm={2}>
														<Form.Control type="text" value={selectedRow.QuantiteParPalette} onChange={e => editRow("QuantiteParPalette", e.target.value)} />
													</Col>
												</Row>
												<Row className="mb-2">
													<Col sm={4}>
														<strong>Quantité vendue :&nbsp;</strong>
													</Col>
													<Col sm={2}>
														{selectedRow.QuantiteVendue}
													</Col>
													<Col sm={4}>
														<strong>Quantité en stock :&nbsp;</strong>
													</Col>
													<Col sm={2}>
														{selectedRow.QuantiteEnStock}
													</Col>
												</Row>
												{composants.map(composant => {
													return (
														<Row className="mb-2">
															<Col sm={6}>
																<strong>{"Composant " + composant.NumComposant + " : "}&nbsp;</strong>
																{composant.CodeSKU + ' ' + composant.LibelleArticle1 + ' ' + composant.LibelleArticle2}
															</Col>
															<Col sm={6}>
																<strong>Qté :&nbsp;</strong>
																{composant.Quantite}
															</Col>
														</Row>
													)
												}
												)}
												{[1, 2, 3].map(elt => {
													if (libelleTag['LibelleTag' + elt] && libelleTag['LibelleTag' + elt] !== '')
														return (
															<Row className="mb-2">
																<Col sm={4}>
																	<strong>{libelleTag['LibelleTag' + elt] + " : "}&nbsp;</strong>
																</Col>
																<Col sm={8}>
																	<Select
																		getOptionLabel={option => option['Tag' + elt]}
																		getOptionValue={option => option['Tag' + elt]}
																		options={tags(elt)}
																		defaultValue={tags(elt).find(element => element['Tag' + elt] === selectedRow['Tag' + elt])}
																		onChange={e => editRow("Tag" + elt, e['Tag' + elt])}
																		isSearchable={true}
																	/>
																</Col>
															</Row>
														)
													else
														return null;
												}
												)}
												{codesRegroupement && codesRegroupement.length > 1 && <Row className="mb-1">
													<Col sm={4}>
														<strong>
															Code Regroupement :
														</strong>
													</Col>
													<Col sm={8}>
														{selectedRow.Statut === 'Illégal' ?
															<Select
																getOptionLabel={option => option.CodeRegroupement}
																getOptionValue={option => option.CodeRegroupement}
																options={codesRegroupement}
																defaultValue={codesRegroupement.find(element => element.CodeRegroupement === selectedRow.CodeRegroupement)}
																onChange={e => editRow("CodeRegroupement", e.CodeRegroupement)}
																isSearchable={true}
															/>
															: selectedRow.CodeRegroupement}
													</Col>
												</Row>}
												{couleurs && couleurs.length > 1 && <Row className="mb-1">
													<Col sm={4}>
														<strong>
															Couleur :
														</strong>
													</Col>
													<Col sm={8}>
														{selectedRow.Statut === 'Illégal' ?
															<Select
																getOptionLabel={option => option.Couleur}
																getOptionValue={option => option.Couleur}
																options={couleurs}
																defaultValue={couleurs.find(element => element.Couleur === selectedRow.Couleur)}
																onChange={e => editRow("Couleur", e.Couleur)}
																isSearchable={true}
															/>
															: selectedRow.Couleur}
													</Col>
												</Row>}
												{tailles && tailles.length > 1 && <Row className="mb-1">
													<Col sm={4}>
														<strong>
															Taille :
														</strong>
													</Col>
													<Col sm={8}>
														{selectedRow.Statut === 'Illégal' ?
															<Select
																getOptionLabel={option => option.Taille}
																getOptionValue={option => option.Taille}
																options={tailles}
																defaultValue={tailles.find(element => element.Taille === selectedRow.Taille)}
																onChange={e => editRow("Taille", e.Taille)}
																isSearchable={true}
															/>
															: selectedRow.Taille}
													</Col>
												</Row>}
												{marques && marques.length > 1 && <Row className="mb-1">
													<Col sm={4}>
														<strong>
															Marque :
														</strong>
													</Col>
													<Col sm={8}>
														{selectedRow.Statut === 'Illégal' ?
															<Select
																getOptionLabel={option => option.Marque}
																getOptionValue={option => option.Marque}
																options={marques}
																defaultValue={marques.find(element => element.Marque === selectedRow.Marque)}
																onChange={e => editRow("Marque", e.Marque)}
																isSearchable={true}
															/>
															: selectedRow.Marque}
													</Col>
												</Row>}
											</div>
											{selectedRow.GestionDesLots &&
												<div className="section-form">
													<Row className="mb-2">
														<Col sm={8}>
															<span data-toggle="tooltip" title="Permet de définir la date maximale d'envoi du lot en fonction de la date d'expiration">
																<strong>
																	Lot : Nombre de jour de durabilité minimale (DDM) :
																</strong>
															</span>
														</Col>
														<Col sm={2}>
															<Form.Control type="text" value={selectedRow.LimiteAvantExpirationLot} onChange={e => editRow("LimiteAvantExpirationLot", e.target.value)} />
														</Col>
													</Row>

												</div>
											}
										</Modal.Body>
										<Modal.Footer>
											{selectedRow.GestionDesLots && <Button variant="default" className="blue" onClick={() => { setLotsByItemVisible(true) }}>Lots</Button>}
											<Button variant="default" className="blue" onClick={() => { ouvrirDiscussion(selectedRow.Id_Article, selectedRow.CodeSKU, selectedRow.LibelleArticle1) }}>Discussions</Button>
											<Button variant="default" className="blue" onClick={() => { handleDocumentList(selectedRow.Id_Article, selectedRow.CodeSKU, selectedRow.LibelleArticle1) }}>Documents</Button>
											<Button variant="default" className="blue" onClick={putSelectedRow}>Valider</Button>
											<Button variant="default" className="blue" onClick={() => { setEditModalShow(false) }}>Annuler</Button>
										</Modal.Footer>
									</Modal>

									<Modal show={createModalShow} backdrop="static" onHide={() => { setCreateModalShow(false) }} size="lg">
										<Modal.Header closeButton>
											<Modal.Title as="h5">
												Fiche article <br />
											</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<div className="section-form">
												<Row className="mb-1">
													<Col sm={3}>
														<strong>
															Code SKU :
														</strong>
													</Col>
													<Col sm={9}>
														<Form.Control type="text" maxLength="40" placeholder="Code" onChange={e => editRow("CodeSKU", e.target.value)} />
													</Col>
												</Row>
												<Row className="mb-1">
													<Col sm={3}>
														<strong>
															Libellé :
														</strong>
													</Col>
													<Col sm={9}>
														<Form.Control type="text" maxLength="80" placeholder="Libellé" onChange={e => editRow("LibelleArticle1", e.target.value)} />
													</Col>
												</Row>
												<Row className="mb-1">
													<Col sm={3}>
														<strong>
															Lib. complémentaire :
														</strong>
													</Col>
													<Col sm={9}>
														<Form.Control type="text" maxLength="80" placeholder="Libellé" onChange={e => editRow("LibelleArticle2", e.target.value)} />
													</Col>
												</Row>
												<Row className="mb-1">
													<Col sm={3}>
														<strong>
															Code-barres EAN :
														</strong>
													</Col>
													<Col sm={3}>
														<InputGroup className="">
															<Form.Control type="text" value={selectedRow.CodeBarrePicking} onChange={e => editRow("CodeBarrePicking", e.target.value)} />
														</InputGroup>
													</Col>
													<Col sm={3}>
														<strong>
															Code douanier :
														</strong>
													</Col>
													<Col sm={3}>
														<InputGroup className="">
															<Form.Control type="text" value={selectedRow.CodeDouanier} onChange={e => editRow("CodeDouanier", e.target.value)} />
														</InputGroup>
													</Col>

												</Row>
												{famillesArticle && famillesArticle.length > 1 && <Row className="mb-2">
													<Col sm={3}>
														<strong>
															Famille :
														</strong>
													</Col>
													<Col sm={9}>
														<Select
															getOptionLabel={option => option.LibelleFamille}
															getOptionValue={option => option.IDFAMILLE_ARTICLE}
															options={famillesArticle}
															defaultValue={famillesArticle.find(element => element.IDFAMILLE_ARTICLE === selectedRow.IDFAMILLE_ARTICLE)}
															onChange={e => editRow("IDFAMILLE_ARTICLE", e.IDFAMILLE_ARTICLE)}
															isSearchable={true}
														/>
													</Col>
												</Row>}
											</div>
											<div className="section-form">
												<Row className="mb-4">
													<Col sm={4}>
														<strong>
															Poids
														</strong>
														<InputGroup className="">
															<Form.Control type="text" value={selectedRow.PoidsArticle} onChange={e => editRow("PoidsArticle", e.target.value)} />
															<InputGroup.Append>
																<InputGroup.Text>kg</InputGroup.Text>
															</InputGroup.Append>
														</InputGroup>
													</Col>
													<Col sm={4}>
														<strong>
															Volume
														</strong>
														<InputGroup className="">
															<Form.Control type="text" value={selectedRow.VolumeArticle} onChange={e => editRow("VolumeArticle", e.target.value)} />
															<InputGroup.Append>
																<InputGroup.Text>m<sup>3</sup></InputGroup.Text>
															</InputGroup.Append>
														</InputGroup>
													</Col>
												</Row>
												<Row className="mb-2">
													<Col sm={4}>
														<strong>
															Hauteur
														</strong>
														<InputGroup className="">
															<Form.Control type="text" value={selectedRow.HauteurArticle} onChange={e => editRow("HauteurArticle", e.target.value)} />
															<InputGroup.Append>
																<InputGroup.Text>cm</InputGroup.Text>
															</InputGroup.Append>
														</InputGroup>
													</Col>
													<Col sm={4}>
														<strong>
															Largeur
														</strong>
														<InputGroup className="">
															<Form.Control type="text" value={selectedRow.LargeurArticle} onChange={e => editRow("LargeurArticle", e.target.value)} />
															<InputGroup.Append>
																<InputGroup.Text>cm</InputGroup.Text>
															</InputGroup.Append>
														</InputGroup>
													</Col>
													<Col sm={4}>
														<strong>
															Longueur :
														</strong>
														<InputGroup className="">
															<Form.Control type="text" value={selectedRow.LongueurArticle} onChange={e => editRow("LongueurArticle", e.target.value)} />
															<InputGroup.Append>
																<InputGroup.Text>cm</InputGroup.Text>
															</InputGroup.Append>
														</InputGroup>
													</Col>
												</Row>
											</div>
											<div className="section-form">
												<Row className="mb-1">
													<Col sm={4}>
														<strong>
															Alerte quantité minimum :
														</strong>
													</Col>
													<Col sm={2}>
														<Form.Control type="text" value={selectedRow.AlerteQuantiteMin} onChange={e => editRow("AlerteQuantiteMin", e.target.value)} />
													</Col>
												</Row>
												<Row className="mb-1">
													<Col sm={4}>
														<strong>
															Quantité par carton :
														</strong>
													</Col>
													<Col sm={2}>
														<Form.Control type="text" value={selectedRow.QuantiteParCarton} onChange={e => editRow("QuantiteParCarton", e.target.value)} />
													</Col>
													<Col sm={4}>
														<strong>
															Quantité par palette :
														</strong>
													</Col>
													<Col sm={2}>
														<Form.Control type="text" value={selectedRow.QuantiteParPalette} onChange={e => editRow("QuantiteParPalette", e.target.value)} />
													</Col>
												</Row>
												{[1, 2, 3].map(elt => {
													if (libelleTag['LibelleTag' + elt] && libelleTag['LibelleTag' + elt] !== '')
														return (
															<Row className="mb-1">
																<Col sm={4}>
																	<strong>{libelleTag['LibelleTag' + elt] + " : "}&nbsp;</strong>
																</Col>
																<Col sm={8}>
																	<Select
																		getOptionLabel={option => option['Tag' + elt]}
																		getOptionValue={option => option['Tag' + elt]}
																		options={tags(elt)}
																		defaultValue={tags(elt).find(element => element['Tag' + elt] === selectedRow['Tag' + elt])}
																		onChange={e => editRow("Tag" + elt, e['Tag' + elt])}
																		isSearchable={true}
																	/>
																</Col>
															</Row>
														)
													else
														return null;
												}
												)}
												{codesRegroupement && codesRegroupement.length > 1 && <Row className="mb-1">
													<Col sm={4}>
														<strong>
															Code Regroupement :
														</strong>
													</Col>
													<Col sm={8}>
														<Select
															getOptionLabel={option => option.CodeRegroupement}
															getOptionValue={option => option.CodeRegroupement}
															options={codesRegroupement}
															defaultValue={codesRegroupement.find(element => element.CodeRegroupement === selectedRow.CodeRegroupement)}
															onChange={e => editRow("CodeRegroupement", e.CodeRegroupement)}
															isSearchable={true}
														/>
													</Col>
												</Row>}
												{couleurs && couleurs.length > 1 && <Row className="mb-1">
													<Col sm={4}>
														<strong>
															Couleur :
														</strong>
													</Col>
													<Col sm={8}>
														<Select
															getOptionLabel={option => option.Couleur}
															getOptionValue={option => option.Couleur}
															options={couleurs}
															defaultValue={couleurs.find(element => element.Couleur === selectedRow.Couleur)}
															onChange={e => editRow("Couleur", e.Couleur)}
															isSearchable={true}
														/>
													</Col>
												</Row>}
												{tailles && tailles.length > 1 && <Row className="mb-1">
													<Col sm={4}>
														<strong>
															Taille :
														</strong>
													</Col>
													<Col sm={8}>
														<Select
															getOptionLabel={option => option.Taille}
															getOptionValue={option => option.Taille}
															options={tailles}
															defaultValue={tailles.find(element => element.Taille === selectedRow.Taille)}
															onChange={e => editRow("Taille", e.Taille)}
															isSearchable={true}
														/>
													</Col>
												</Row>}
												{marques && marques.length > 1 && <Row className="mb-1">
													<Col sm={4}>
														<strong>
															Marque :
														</strong>
													</Col>
													<Col sm={8}>
														<Select
															getOptionLabel={option => option.Marque}
															getOptionValue={option => option.Marque}
															options={marques}
															defaultValue={marques.find(element => element.Marque === selectedRow.Marque)}
															onChange={e => editRow("Marque", e.Marque)}
															isSearchable={true}
														/>
													</Col>
												</Row>}
											</div>
										</Modal.Body>
										<Modal.Footer>
											<Button variant="default" className="blue" onClick={createSelectedRow}>Valider</Button>
											<Button variant="default" className="blue" onClick={() => { setCreateModalShow(false) }}>Annuler</Button>
										</Modal.Footer>
									</Modal>
								</div>

							</div>

						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
	else {
		return null
	}

}

export default Articles;