import React, { useEffect, useState } from 'react';
import logecomApi from "../../utils/axiosInstance";
import { downloadCSV, patchFilterFactory, dateIso, dateSort } from "../../utils/utils";
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { Spinner } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next';
import { Col, Row, Button, ButtonGroup, ToggleButton } from "react-bootstrap"
import { InputGroup } from "react-bootstrap"
import LotEdit from './LotEdit';
import ReceptionsByLot from '../Reception/ReceptionsByLot';

const apiRoot = process.env.REACT_APP_API_ROOT

const LotsListTable = ({ client, articleId, modeSelection, setLot }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const checkValidity = res => {
        return res === undefined || res === "" || res === {} ? [] : res
    };
    const [exportData, setExportData] = useState([]);
    const filtreEnum = { Tous: 0, AvecStock: 1 };
    const [filtre, setFiltre] = useState(filtreEnum.AvecStock);
    const [editLotId, setEditLotId] = useState(null);
    const [receptionsByLotVisible, setReceptionsByLotVisible] = useState(null);
    const [titreElement, setTitreElement] = useState("");

    useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client]
    );

    const refresh = () => {
        if (!client || parseInt(client) === 0) {
            return;
        }
        setLoading(true)
        if (articleId > 0) {
            logecomApi.get(apiRoot + 'items/' + client + '/' + articleId + '/lots').then((res) => {
                setData(checkValidity(res.data))
                setEditLotId(null)
                setReceptionsByLotVisible(null)
            }).catch((error) => {
                console.log(JSON.stringify(error))
            })

        } else {
            logecomApi.get(apiRoot + 'clients/' + client + '/lots').then((res) => {
                setData(checkValidity(res.data))
                setEditLotId(null)
                setReceptionsByLotVisible(null)
            }).catch((error) => {
                console.log(JSON.stringify(error))
            })
        }
        setLoading(false)
    };


    const headerWidth = (width) => (colum, colIndex) => {
        return { width: width + 'px', fontSize: '12.5px', lineHeight: '14px' };
    };

    const rowStyle = (row, rowIndex) => {
        if (rowIndex % 2 === 1) {
            return { backgroundColor: '#dddddd' };
        }
    };

    const handleOnSelect = (row, isSelect) => {
        setLot(row);
        return true;
    };

    const RefreshFiltre = (filtreSelect) => {
        refresh();
        setFiltre(filtreSelect);
    };
    const selectRow = () => {
        if (modeSelection) {
            return {
                mode: 'radio',
                clickToSelect: true,
                hideSelectColumn: true,
                style: { backgroundColor: 'blue', color: 'white' },
                onSelect: handleOnSelect
            };
        }
    };

    const myTableFilterFactory = patchFilterFactory(filterFactory, (filteredData) => {
        setExportData(prevData => {
            if (JSON.stringify(prevData) !== JSON.stringify(filteredData)) {
                return filteredData
            }
            return prevData
        })
    })

    const filteredData = () => {
        if (data === undefined || data === "" || data === {}) {
            return [];
        } else {
            return data.filter(function (row) {
                let affiche = true;
                switch (filtre) {
                    case filtreEnum.AvecStock:
                        affiche = (row.QuantiteEnStock > 0);
                        break;
                    case filtreEnum.Tous:
                        affiche = true;
                        break;
                    default:
                        break;
                }
                return affiche;
            })
        }
    }


    const openEditModal = (row) => {
        setEditLotId(row.IDLOT)
    }

    const openReceptionsModal = (row) => {
        setReceptionsByLotVisible(row.IDLOT)
        setTitreElement(' du lot numéro: ' + row.NumeroDeLot)
    }

    const controlsFormatter = (_, row) => {

        return (
            <div className="btn-group">
                <button type="button" title="Modifier" className="btncommande btn-sm btn-primary" style={{ marginRight: '5px' }} onClick={() => openEditModal(row)}>
                    <i className="ion ion-md-create d-block"></i>
                </button>
                <button type="button" title="Réceptions" className="btncommande btn-sm btn-primary" style={{ marginRight: '5px' }} onClick={() => openReceptionsModal(row)}>
                    <img src="assets/img/Camion.png" width="11" height="14" alt="Logecom" />
                </button>
            </div>
        )
    }

    // La 1ère valeur est l'en-tête de colonne, la 2ème le nom de la propriété renvoyée par l'API, la 3ème la largeur de la colonne en pixels  
    const columns =
        [
            ["", "", 60, true, false],
            ["ID", "IDLOT", 180, false, false],
            ["Numéro de lot", "NumeroDeLot", 120, true, true],
            ["Statut", "Statut", 160, true, true],
            ["SKU", "CodeSKU", 180, true, true],
            ["Libellé", "LibelleArticle1", 250, true, true],
            ["Date d'expiration", "DateExpiration", 150, true, true],
            ["DLCFormatAnglais", "DateExpirationFormatAnglais", 180, false, false],
            ["Date de Durabilité Minimale", "DateDurabiliteMinimale", 150, true, true],
            ["DDMFormatAnglais", "DateDurabiliteMinimaleFormatAnglais", 180, false, false],
            ["Quantité en stock", "QuantiteEnStock", 90, true, true]
            //["Quantité receptionnée", "QuantiteReceptionne", 60, false, true],
        ]

            .map(elt => {
                let result = { text: elt[0], dataField: elt[1], visible: elt[3], exporter: elt[4], headerStyle: headerWidth(elt[2]), filter: textFilter(), sort: true }
                result.formatExtraData = result.dataField;

                if (result.visible === false) {
                    result.hidden = true;
                };
                if (result.dataField === "") {
                    result.isDummyField = true
                    result.CSVExport = false
                    result.formatter = controlsFormatter
                    result.sort = false
                    result.filter = undefined
                    result.hidden = modeSelection;
                };

                if (["QuantiteEnStock", "QuantiteReceptionne",].includes(result.dataField)) {
                    result.style = { textAlign: 'right', verticalAlign: 'middle' };
                };

                if (articleId > 0 && ['CodeSKU', "LibelleArticle1"].includes(result.dataField)) {
                    result.hidden = true;
                };
                if (["DateExpiration", "DateDurabiliteMinimale"].includes(result.dataField)) {
                    result.sortFunc = dateSort;

                    result.style = (cell, row, rowIndex, colIndex) => {
                        var today = dateIso(new Date())
                        const day = cell.split("/")[0]
                        const month = cell.split("/")[1]
                        const year = cell.split("/")[2]
                        var d1 = year + "-" + month + "-" + day
                        if (d1 < today) {
                            return { color: '#f44336', fontWeight: 'bold' };
                        }
                    }
                };


                if (result.dataField === "Statut") {
                    var statuts = [
                        { value: 1, label: 'Bloqué' },
                        { value: 2, label: 'Expiré' },
                        { value: 4, label: 'Épuisé' },
                        { value: 3, label: 'A détruire' },
                        { value: 10, label: 'A expédier sans priorité' },
                        { value: 20, label: 'A expédier priorité 1' },
                        { value: 19, label: 'A expédier priorité 2' },
                        { value: 18, label: 'A expédier priorité 3' },
                        { value: 17, label: 'A expédier priorité 4' },
                        { value: 16, label: 'A expédier priorité 5' },
                        { value: 15, label: 'A expédier priorité 6' },
                        { value: 14, label: 'A expédier priorité 7' },
                        { value: 13, label: 'A expédier priorité 8' },
                        { value: 12, label: 'A expédier priorité 9' },
                        { value: 11, label: 'A expédier priorité 10' },
                    ];
                    result.formatter = cell => statuts.find(statut => statut.value === cell).label;
                    result.filter = selectFilter({
                        options: {
                            1: 'Bloqué',
                            2: 'Expiré',
                            4: 'Épuisé',
                            3: 'A détruire',
                            10: 'A expédier sans priorité',
                            20: 'A expédier priorité 1',
                            19: 'A expédier priorité 2',
                            18: 'A expédier priorité 3',
                            17: 'A expédier priorité 4',
                            16: 'A expédier priorité 5',
                            15: 'A expédier priorité 6',
                            14: 'A expédier priorité 7',
                            13: 'A expédier priorité 8',
                            12: 'A expédier priorité 9',
                            11: 'A expédier priorité 10'
                        },
                        className: 'custom-select',
                        placeholder: 'Tous'
                    })
                }
                return result
            })


    return (
        <>
            {loading ?
                <div style={{ textAlign: 'center' }}>
                    <Spinner animation="border" role="status" style={{ color: 'blue' }} size="xl">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
                :

                <ToolkitProvider
                    keyField="IDLOT"
                    data={filteredData()}
                    columns={columns}
                    bootstrap4
                    search
                    columnToggle
                >
                    {props => (
                        <div>
                            {<Row style={{ marginBottom: '20px' }}>
                                <Col>
                                    {articleId < 1 &&
                                        <span className="titre-section font-weight-bold">Lots</span>
                                    }
                                </Col>
                                <Col md="auto">
                                    <div className="header-controlesX">
                                        <InputGroup>
                                            <div style={{ width: '95px' }}></div>
                                            <ButtonGroup style={{ marginLeft: '5px', marginRight: '5px' }} toggle className="blue">
                                                <ToggleButton
                                                    type="checkbox"
                                                    variant="secondary"
                                                    checked={filtre === filtreEnum.Tous}
                                                    value="1"
                                                    onChange={(e) => { RefreshFiltre(filtreEnum.Tous) }}
                                                >
                                                    Tous
                                                </ToggleButton>
                                                <ToggleButton
                                                    type="checkbox"
                                                    variant="secondary"
                                                    checked={filtre === filtreEnum.AvecStock}
                                                    value="1"
                                                    onChange={(e) => { RefreshFiltre(filtreEnum.AvecStock) }}
                                                >
                                                    Avec stock
                                                </ToggleButton>
                                            </ButtonGroup>
                                            {articleId < 1 &&
                                                <Button variant="default" className="blue" onClick={() => { downloadCSV(exportData, columns, 'Lots') }}>Export CSV</Button>
                                            }
                                        </InputGroup>
                                    </div>
                                </Col>
                            </Row>}
                            <BootstrapTable
                                {...props.baseProps}
                                rowStyle={rowStyle}
                                wrapperClasses="table-responsive"
                                pagination={paginationFactory({ sizePerPageList: [20, 50, 100, 250], sizePerPage: 50 })}
                                filter={myTableFilterFactory()}
                                selectRow={selectRow()}
                            />
                        </div>

                    )}
                </ToolkitProvider>
            }
            <LotEdit
                visible={editLotId}
                fermer={() => { setEditLotId(null); refresh(); }}
                lotId={editLotId}
            />

            <ReceptionsByLot
                isModalVisible={receptionsByLotVisible}
                fermer={() => { setReceptionsByLotVisible(null); refresh(); }}
                client={client}
                lotId={receptionsByLotVisible}
                titre={titreElement}
            />

        </>
    )
}

export default LotsListTable;