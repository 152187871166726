import React, { useEffect, useState } from 'react';
import logecomApi from "../../utils/axiosInstance";
import { Helmet } from 'react-helmet';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter'
import { Col, Row, Button, ButtonGroup, ToggleButton } from "react-bootstrap"
import { Spinner } from 'react-bootstrap'
import DocumentList from '../../Components/Document/DocumentList';
import DiscussionModal from '../..//Components/Discussion/DiscussionModal';
import OrderWatch from './OrderWatch';
import YesNo from '../../utils/YesNo';
import { dateSort, downloadCSV, patchFilterFactory, labelDureeJMA } from "../../utils/utils";
import ImportOrder from './ImportOrder';
import ExportDetailleOrder from './ExportDetailleOrder';
import SavModal from '../Sav/SavModal';
import CommandeEdit from './CommandeEdit';

const apiRoot = process.env.REACT_APP_API_ROOT

const Commande = (props) => {
	const filtreEnum = { Tous: 0, EnRupture: 1, NonSoldees: 2 };
	const client = props.client;
	const idBoutique = props.idBoutique;
	const [orderId, setOrderId] = useState(null);
	const [clients, setClients] = useState([]);
	const [statusOrder, setStatusOrder] = useState([]);
	const [personnalisations, setPersonnalisations] = useState([])
	const [data, setData] = useState([]);
	const [exportData, setExportData] = useState([]);
	const [loading, setLoading] = useState(false)
	const [importVisible, setImportVisible] = useState(false);
	const [exportDetailleVisible, setExportDetailleVisible] = useState(false);
	const [watchOrderId, setWatchOrderId] = useState(null);
	const [selectedRow, setSelectedRow] = useState({})
	const [duration, setDuration] = useState(7);
	const [documentList, setDocumentList] = useState(false);
	const [idElementRattache, setIdElementRattache] = useState(0);
	const [titreElement, setTitreElement] = useState(false);
	const [discussionVisible, setDiscussionVisible] = useState(false);
	const [savVisible, setSavVisible] = useState(false);
	const [actionWsEnCours, setActionWsEnCoursEnCours] = useState(false);
	const [boutiques, setBoutiques] = useState([]);
	const [yesNoDeleteRow, setYesNoDeleteRow] = useState(false);
	const [filtre, setFiltre] = useState(filtreEnum.Tous);

	const handleDocumentList = (idcommande, numCommandeClient, dateCommande) => {
		if (typeof idcommande === 'number') {
			setIdElementRattache(idcommande);
			setTitreElement('Commande ' + numCommandeClient + ' du ' + dateCommande);
		}
		setDocumentList(true);
	};

	const getOrders = async () => {
		setLoading(true);
		return await logecomApi.get(apiRoot + 'clients/' + client + '/orders?duree=' + duration + (idBoutique > 0 ? "&boutique=" + idBoutique : "") + '&filtre=' + filtre).then((res) => {
			setLoading(false)
			return res.data;
		}).catch((error) => {
			setLoading(false)
			throw new Error(error);
		})
	}

	const importer = () => {
		if (idBoutique < 1) {
			alert("Merci de sélectionner une boutique")
		} else {
			setImportVisible(true);
		}
	}

	const exportDetaille = () => {
		if (idBoutique < 1) {
			alert("Merci de sélectionner une boutique")
		} else {
			setExportDetailleVisible(true);
		}
	}

	const ouvrirDiscussion = (idcommande, numCommandeClient, dateCommande) => {
		setIdElementRattache(idcommande);
		setTitreElement('Commande ' + numCommandeClient + ' du ' + dateCommande);
		setDiscussionVisible(true);
	};
	const ouvrirSav = (idcommande, numCommandeClient, dateCommande) => {
		setIdElementRattache(idcommande);
		setTitreElement('Commande ' + numCommandeClient + ' du ' + dateCommande);
		setSavVisible(true);
	};
	const checkValidity = res => {
		return res === undefined || res === "" ? [] : res
	}

	useEffect(() => {
		logecomApi.get(apiRoot + 'status/orders').then((res) => {
			var options = {};
			res.data.forEach(element => options[element.Code_Statut.toString()] = element.Lib_Statut);
			setStatusOrder(options)
		}).catch((error) => {
			console.log(JSON.stringify(error))
		})

	}, []);

	const myTableFilterFactory = patchFilterFactory(filterFactory, (filteredData) => {
		setExportData(prevData => {
			if (JSON.stringify(prevData) !== JSON.stringify(filteredData)) {
				return filteredData
			}
			return prevData
		})
	})

	const filteredData = () => {
		// On prend les premiers caractères des commentaires pour limiter l'affichage
		if (data === undefined || data === "" || !(data instanceof Array)) {
			return [];
		} else {
			var _dataFiltered = data;
			_dataFiltered.forEach(function (part, index, theArray) {
				theArray[index].Commentaire = theArray[index].Commentaire.substring(0, 150);
			});
			_dataFiltered = _dataFiltered.filter(function (row) {
				let affiche = true;
				switch (filtre) {
					case filtreEnum.EnRupture:
						affiche = (row.RuptureStock === 1);
						break;
					case filtreEnum.NonSoldees:
						affiche = ['En Attente', 'En Cours', 'Problème'].includes(row.StatutCommande);
						break;
					default:
						break;
				}
				return affiche;
			});
			return _dataFiltered;
		}
	}

	useEffect(() => {
		var _exportData = filteredData();
		if (boutiques && boutiques.length > 0) {
			_exportData.forEach(function (part, index, theArray) {
				var boutique = boutiques.find(boutique => boutique.IDBOUTIQUE === theArray[index].IDBOUTIQUE);
				if (boutique) {
					theArray[index].NomBoutique = boutique.Nom;
				}
			});
		}
		setExportData(_exportData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	useEffect(() => {
		localStorage.setItem("page", "commande")
		logecomApi.get(apiRoot + 'clients').then((res) => {
			setClients(res.data)
		}).catch((error) => {
			console.log(JSON.stringify(error))
		})
	}, []);

	useEffect(() => {
		if (!client || parseInt(client) === 0) {
			return;
		}
		getOrders().then((orders) => {
			setData(orders)
		}).catch((error) => {
			console.log(JSON.stringify(error))
		})

		logecomApi.get(apiRoot + 'clients/' + client + '/shops').then((res) => {
			setBoutiques(res.data);
		}).catch((error) => {
			console.log(JSON.stringify(error))
		})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [client, duration, idBoutique, filtre, filtreEnum.EnRupture]
	);

	useEffect(() => {
		if (!client || parseInt(client) === 0) {
			return;
		}
		logecomApi.get(apiRoot + 'clients/' + client + '/customization_type').then((res) => {
			if (res.data.length > 0) {
				var premier = { IDTYPE_PERSONNALISATION: 0, NomTypePersonnalisation: 'Sans', Statut: 'Actif' };
				res.data = [premier].concat(res.data)
			}
			setPersonnalisations(checkValidity(res.data))
		}).catch((error) => {
			console.log(JSON.stringify(error))
		})
	}, [client]
	);

	const openWatchModal = (row) => {
		logecomApi.get(apiRoot + 'orders/' + row.IDCOMMANDE).then((res) => {
			setWatchOrderId(row.IDCOMMANDE)
			setSelectedRow(res.data[0])
		}).catch((error) => {
			console.log(JSON.stringify(error))
		})
	}

	const isIE10Mode = document['documentMode'] === 10

	const urlTrackingFormatter = (_, row) => {
		if (row.EXPE_UrlTracking && row.EXPE_UrlTracking.startsWith('http')) {
			return (
				row.EXPE_UrlTracking && row.EXPE_UrlTracking.startsWith('http') ?
					<a target="_blank" rel="noreferrer" href={row.EXPE_UrlTracking}>{row.EXPE_NumTracking}</a> :
					row.NumTracking

			)
		} else {
			return (
				row.EXPE_NumTracking
			)
		}
	}
	// Définit les contrôles présents sur les lignes
	const controlsFormatter = (_, row) => {
		let coulBtnDiscussion = '#fff';
		let titleDiscussion = 'Discussion fermée';
		if (row.nbMessagesNonLu > 0) {
			coulBtnDiscussion = 'red';
			titleDiscussion = 'Discussion avec des messages non lus';
		} else if (row.nbDiscussionOuverte > 0) {
			coulBtnDiscussion = 'green';
			titleDiscussion = 'Discussion ouverte';
		}
		let coulBtnSav = '#fff';
		let titleSav = 'SAV fermé';
		if (row.nbSavOuvert > 0) {
			coulBtnSav = 'red';
			titleSav = 'SAV ouverts';
		}
		return (
			<div className="btn-group">
				<button type="button" title="Détails" className="btncommande btn-sm btn-primary" style={{ marginRight: '5px' }} onClick={() => openWatchModal(row)}>
					<i className="ion ion-md-eye d-block"></i>
				</button>
				{row.Modifiable === 1 ?
					<React.Fragment>
						<button type="button" title="Modifier" className="btncommande btn-sm btn-primary" style={{ marginRight: '5px' }} onClick={() => setOrderId(row.IDCOMMANDE)}>
							<i className="ion ion-md-create d-block"></i>
						</button>
						<button type="button" title="Supprimer" className="btncommande btn-sm btn-primary" style={{ marginRight: '5px' }} onClick={() => { setSelectedRow(row); setYesNoDeleteRow(true) }}>
							<i className="ion ion-md-trash d-block"></i>
						</button>
					</React.Fragment> : null}
				{row.docExiste === 1 ?
					<button type="button" className="btncommande btn-sm btn-primary" style={{ marginRight: '5px' }} title='Documents rattachés' onClick={() => handleDocumentList(row.IDCOMMANDE, row.NumCommandeClient, row.DateCommande)}>
						<i className="ion ion-md-document d-block"></i>
					</button> : null}
				{row.nbMessages > 0 ?
					<button type="button" className="btncommande btn-sm btn-primary" style={{ marginRight: '5px', color: coulBtnDiscussion }} title={titleDiscussion} onClick={() => ouvrirDiscussion(row.IDCOMMANDE, row.NumCommandeClient, row.DateCommande)}>
						<i className="ion ion-md-chatbubbles d-block"></i>
					</button> : null}
				{row.nbSav > 0 ?
					<button type="button" className="btncommande btn-sm btn-primary" style={{ marginRight: '5px', color: coulBtnSav }} title={titleSav} onClick={() => ouvrirSav(row.IDCOMMANDE, row.NumCommandeClient, row.DateCommande)}>
						<i className="ion ion-md-settings d-block"></i>
					</button> : null}
			</div>
		)
	}


	const headerWidth = (width) => () => {
		return { width: width + 'px', fontSize: '12.5px', lineHeight: '14px' };
	}

	// La 1ère valeur est l'en-tête de colonne, la 2ème le nom de la propriété renvoyée par l'API, la 3ème la largeur de la colonne en pixels  
	const columns =
		[["", "watch", 125, false],
		["ID", "IDCOMMANDE", 0, false], ["Modifiable", "Modifiable", 0, false], ["Boutique", "IDBOUTIQUE", 90, false], ["NomBoutique", "NomBoutique", 0, true],
		["N° commande", "NumCommandeClient", 130, true], ["Ind.", "IndiceCommande", 40, true],
		["StatutCommande", "StatutCommande", 0, true], ["Type", "TypeCommande", 60, true],
		["Statut", "CodeStatutCommande", 90, false], ["Date", "DateCommande", 90, true], ["Soc.", "Societe", 150, true],
		["Nom", "NomLivraison", 180, true], ["Adr.", "AdresseLivraison", 80, true], ["Complément d'adr.", "ComplAdresseLivraison", 80, true],
		["CP", "CodePostalLivraison", 60, true], ["Ville", "VilleLivraison", 170, true],
		["Pays", "CodePaysLivraison", 40, true], ["Commentaire", "Commentaire", 170, true], ["Transporteur", "EXPE_Transporteur", 80, true],
		["Date création étiquette", "DateCreationEtiquette", 90, true], ["Option transport", "EXPE_OptionTransport", 100, true], ["N° suivi", "EXPE_NumTracking", 120, true],
		["Date maxi envoi", "DateMaxiEnvoi", 85, true]]
			.map(elt => {
				let result = { text: elt[0], dataField: elt[1], exporter: elt[3], headerStyle: headerWidth(elt[2]), filter: textFilter(), sort: true }
				let colsHidden = ['IDCOMMANDE', 'Modifiable', 'AdresseLivraison', "ComplAdresseLivraison", "StatutCommande", "NomBoutique", "EXPE_OptionTransport"];
				if (result.dataField === "watch") {
					result.isDummyField = true
					result.CSVExport = false
					result.formatter = controlsFormatter
					result.sort = false
					result.filter = undefined
				} else if (result.dataField === "DateCreationEtiquette" || result.dataField === "DateCommande" || result.dataField === "DateMaxiEnvoi") {
					result.sortFunc = dateSort
				} else if (result.dataField === "EXPE_NumTracking") {
					result.formatter = urlTrackingFormatter
				} else if (colsHidden.includes(result.dataField)) {
					result.hidden = true
				} else if (result.dataField === "CodeStatutCommande") {
					result.formatter = cell => statusOrder[cell];
					result.filter = selectFilter({
						options: statusOrder,
						className: 'custom-select',
						placeholder: 'Tous les statuts'
					})
				} else if (result.dataField === "IDBOUTIQUE") {
					result.formatter = (idboutique) => {
						if (!boutiques || boutiques.length === 0) {
							return null;
						}
						var boutique = boutiques.find(boutique => boutique.IDBOUTIQUE === idboutique);
						if (boutique) {
							return boutique.Nom;
						}
					}

					var options = {};
					boutiques.forEach(boutique => options[boutique.IDBOUTIQUE] = boutique.Nom);
					result.filter = selectFilter({
						options: options,
						className: 'custom-select',
						placeholder: 'Toutes les boutiques'
					})
					if (idBoutique > 0) {
						result.hidden = true
					}
				} else if (result.dataField === "TypeCommande") {
					var types = [
						{ value: 'B2B', label: 'B2B' },
						{ value: 'B2C', label: 'B2C' },
					];
					result.formatter = (cell) => {
						let type = types.find(type => type.value === cell);
						if (type) {
							return type.label;
						} else {
							return null;
						}
					};
					result.filter = selectFilter({
						options: {
							'B2B': 'B2B',
							'B2C': 'B2C',
						},
						className: 'custom-select',
						placeholder: 'Tous'
					})
				}
				return result
			})


	// Change le CSS d'une ligne en fonction de l'index
	const rowStyle = (row, rowIndex) => {
		if (data[rowIndex] && data[rowIndex].Qte_EnStock < data[rowIndex].Seuil_Alerte) {
			if (rowIndex % 2 === 0) {
				return { backgroundColor: '#f44336' };
			}
			else {
				return { backgroundColor: '#d42316' };
			}
		}
		if (rowIndex % 2 === 1) {
			return { backgroundColor: '#dddddd' };
		}
	};

	const deleteRow = (row) => {
		if (actionWsEnCours) {
			return;
		}
		setActionWsEnCoursEnCours(true);
		logecomApi.delete(apiRoot + 'orders/' + row.IDCOMMANDE).then(() => {
			setActionWsEnCoursEnCours(false);
			getOrders().then((orders) => {
				setData(orders);
			}).catch((error) => {
				setActionWsEnCoursEnCours(false);
				console.log(JSON.stringify(error))
			})
		}).catch((error) => {
			setActionWsEnCoursEnCours(false);
			alert(error.response.data.fault.detail)
		})
	}
	if (clients.length > 0) {
		return (
			<React.Fragment>
				<Helmet><title>LOGeCOM - Commande</title>

				</Helmet>
				<div className="layout-wrapper layout-1 layout-without-sidenav">
					<div className="layout-inner">

						<div className="layout-container">
							<div className="layout-content">

								<div className="container flex-grow-1 mycontainer-p-y unbox">
									{loading ?
										<div style={{ textAlign: 'center' }}><Spinner animation="border" role="status" style={{ color: 'blue' }} size="xl">
											<span className="sr-only">Loading...</span>
										</Spinner></div> :
										<div>
											{isIE10Mode && <div className="alert alert-danger">
												<strong>react-bootstrap-table2</strong> doesn't support Internet Explorer 10
											</div>}

											{!isIE10Mode && <React.Fragment>

												<ToolkitProvider
													keyField="IDCOMMANDE"
													data={filteredData()}
													columns={columns}
													bootstrap4
													search
													columnToggle
												>
													{props => (
														<div>
															<Row className="align-items-center" style={{ marginBottom: '5px' }}>
																<Col><span className="titre-section font-weight-bold">Commandes</span>
																</Col>
																<Col md="auto">
																	<div className="header-controles">
																		<button type="button" className="btn btn-md btn-primary" style={{ marginRight: '5px', height: '38px' }} onClick={() => { setOrderId(0) }}>
																			<i className="ion ion-md-add-circle d-block"></i>
																		</button>
																		<Button title="Export de la liste des commandes affichées dans le tableau ci-dessous" variant="default" className="blue" onClick={() => { downloadCSV(exportData, columns, 'Commandes') }}>Export CSV</Button>
																		<Button title="Export détaillé des commandes et lignes de commandes correspondant à des critères" style={{ marginLeft: '5px' }} variant="default" className="blue" onClick={() => { exportDetaille() }}>Export détaillé</Button>
																		<Button style={{ marginLeft: '5px' }} variant="default" className="blue" onClick={() => { importer() }}>Import Excel</Button>
																		<ButtonGroup style={{ marginLeft: '5px' }} toggle className="blue">
																			<ToggleButton
																				type="checkbox"
																				variant="secondary"
																				checked={filtre === filtreEnum.Tous}
																				value="1"
																				onChange={() => { setFiltre(filtreEnum.Tous) }}
																			>
																				Toutes
																			</ToggleButton>
																			<ToggleButton
																				type="checkbox"
																				variant="secondary"
																				checked={filtre === filtreEnum.EnRupture}
																				value="1"
																				onChange={() => { setFiltre(filtreEnum.EnRupture) }}
																			>
																				En rupture
																			</ToggleButton>
																			<ToggleButton
																				type="checkbox"
																				variant="secondary"
																				checked={filtre === filtreEnum.NonSoldees}
																				value="1"
																				onChange={() => { setFiltre(filtreEnum.NonSoldees) }}
																			>
																				Non soldées
																			</ToggleButton>
																		</ButtonGroup>
																		{filtre === filtreEnum.Tous && <div style={{ marginLeft: '5px' }} className="btn-group btn-group-toggle" data-toggle="buttons" onChange={(event) => { setDuration(event.target.value) }}	>
																			{[7, 14, 30, 60, 90, 365].map((n) => { return duration === n | duration === n.toString() ? <label key={n} className="btn btn-secondary active focus"><input type="radio" name="selection-duree" value={n} /> {labelDureeJMA(n)}</label> : <label key={n} className="btn btn-secondary"><input type="radio" name="selection-duree" value={n} /> {labelDureeJMA(n)} </label> }
																			)}
																		</div>}
																	</div>
																</Col>
															</Row>
															<BootstrapTable
																{...props.baseProps}
																rowStyle={rowStyle}
																wrapperClasses="table-responsive"
																pagination={paginationFactory({ sizePerPageList: [20, 50, 100, 250], sizePerPage: 50 })}
																filter={myTableFilterFactory()}


															/>
														</div>
													)}
												</ToolkitProvider>


											</React.Fragment>}
										</div>}

									<DocumentList
										isModalVisible={documentList}
										elementRattache='Order'
										idElementRattache={idElementRattache}
										client={client}
										titre={titreElement}
										handleDocumentList={() => setDocumentList(!documentList)}
									/>
									<DiscussionModal
										isModalVisible={discussionVisible}
										elementRattache='Order'
										idElementRattache={idElementRattache}
										client={client}
										titre={titreElement}
										fermerDiscussion={() => setDiscussionVisible(false)}
									/>
									<SavModal
										isModalVisible={savVisible}
										idElementRattache={idElementRattache}
										client={client}
										titre={titreElement}
										fermerSav={() => setSavVisible(false)}
									/>
									<ImportOrder
										visible={importVisible}
										fermer={() => setImportVisible(false)}
										idBoutique={idBoutique}
									/>
									<ExportDetailleOrder
										visible={exportDetailleVisible}
										fermer={() => setExportDetailleVisible(false)}
										idBoutique={idBoutique}
									/>
									<YesNo show={yesNoDeleteRow} hide={setYesNoDeleteRow} callbackfunction={deleteRow} object={selectedRow} title="Suppression" body="Confirmez-vous la suppression de cette commande ?"></YesNo>

									<OrderWatch
										isModalVisible={watchOrderId}
										fermer={() => { setWatchOrderId(null) }}
										orderId={watchOrderId}
									/>

									<CommandeEdit
										client={client}
										fermer={(reload) => {
											setOrderId(null);
											if (reload) {
												getOrders().then((orders) => {
													setData(orders)
												}).catch((error) => {
													console.log(JSON.stringify(error))
												})

											}
										}
										}
										orderId={orderId}
										idBoutique={idBoutique}
										personnalisations={personnalisations}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment >

		);
	}
	else {
		return null
	}

}

export default Commande;



