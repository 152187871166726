import React, { useEffect, useState } from 'react';
import { Modal, Form, Col, Row, Button, Spinner } from "react-bootstrap"
import Select from 'react-select'
import Alerte from '../Alerte';
import logecomApi from "../../utils/axiosInstance";
const apiRoot = process.env.REACT_APP_API_ROOT;

const ImportOrder = ({ idBoutique, visible, fermer }) => {
    const year = new Date().getFullYear();
    const year2digits = new Date().getFullYear().toString().substr(-2);
    const formatDateOptions = [
        { value: 'JJ/MM/AAAA', label: "JJ/MM/AAAA exemple 24/12/" + year + " pour le 24 décembre " + year },
        { value: 'JJ-MM-AAAA', label: "JJ-MM-AAAA exemple 24-12-" + year + " pour le 24 décembre " + year },
        { value: 'J/M/AA', label: "J/M/AA exemple 7/6/" + year2digits + " pour le 7 juin " + year },
        { value: "MM-JJ-AAAA", label: "MM-JJ-AAAA exemple 12-24-" + year + " pour le 24 décembre " + year, },
        { value: 'MM-JJ-AA', label: "MM-JJ-AA exemple 12-24-" + year2digits + " pour le 24 décembre " + year },
        { value: 'AAAAMMJJ', label: "AAAAMMJJ exemple " + year + "1224 pour le 24 décembre " + year },
    ];

    const [message, setMessage] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [textError, setTextError] = useState(null);
    const [formValid, setFormValid] = useState(false);
    const [formatDate, setFormatDate] = useState(formatDateOptions.find(type => type.value === 'JJ/MM/AAAA'));

    const separateurColonneOptions = [
        { value: ',', label: "Virgule" },
        { value: ';', label: "Point-virgule" },
        { value: ':', label: "Deux points" },
        { value: '=', label: "Signe égal" },
        { value: ' ', label: "Espace" },
        { value: '\t', label: "Tabulation" },
    ];

    useEffect(() => {
        let valid = selectedFile && textError === null;
        setFormValid(valid);
    }, [selectedFile, textError]);

    const onFileChange = event => {
        var file = event.target.files[0];
        setSelectedFile(file);
        setTextError(null);
    };
    const onUploadProgress = event => {
        setProgress(Math.round((100 * event.loaded) / event.total));
    };
    const close = () => {
        setSelectedFile(null);
        setTextError(null);
        fermer()
    }
    const HtmlError = () => {
        return (
            <div className="content" dangerouslySetInnerHTML={{ __html: textError }}></div>
        );
    }
    async function importer() {
        setTextError(null);
        const toBase64 = file => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });

        const data = {
            Nom_Fichier: selectedFile.name,
            FormatDate: formatDate.value,
            Donnees_Fichier: await toBase64(selectedFile),
        }

        setProgress(0);
        setUploading(true);
        logecomApi.post(apiRoot + 'orders/' + idBoutique + '/import', data, { onUploadProgress }).then((dataRes) => {
            setUploading(false);
            setProgress(0);
            if (parseInt(dataRes.data.status_Code) === 201) {
                setMessage("Fichier correctement importé");
            } else {
                setMessage("Un problème est survenu lors de l'importation. Commande non importé");
            }
            close();
        }).catch((error) => {
            setUploading(false);
            setProgress(0);
            setTextError(error.response.data.fault !== undefined ? error.response.data.fault.detail : error.message);
        })
    }


    return (
        <div>
            <Modal show={visible} backdrop="static" onHide={fermer} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Importation d'un fichier de commande</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {uploading ?
                        <div style={{ textAlign: 'center' }}><Spinner animation="border" role="status" style={{ color: 'blue' }} size="xl">
                            <span className="sr-only">Loading...</span>
                        </Spinner></div> : null}
                    <div className="section-form">
                        <Row>
                            <Col sm={3}>
                                <Form.Label >
                                    <strong>Fichier :&nbsp; </strong>
                                </Form.Label>
                            </Col>
                            <Col sm={9}>
                                <Form.Control accept=".xlsx,.xls" type="file" onClick={() => { setTextError(null); }} onChange={onFileChange} id="fichier" label="" />
                            </Col>
                        </Row>
                        {uploading && (
                            <div className="progress">
                                <div
                                    className="progress-bar progress-bar-info progress-bar-striped"
                                    role="progressbar"
                                    aria-valuenow={progress}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{ width: progress + "%" }}
                                >
                                    {progress}%
                                </div>
                            </div>
                        )}
                        {textError && <Row className="mb-2">
                            <Col sm={12}>
                                <strong style={{ color: 'red' }}>{HtmlError()}</strong>
                            </Col>
                        </Row>}
                    </div>
                    <div className="section-form">
                        <Row className="mb-2">
                            <Col sm={3}>
                                <strong>Format de date :</strong>
                            </Col>
                            <Col sm={9}>
                                <Select
                                    getOptionLabel={option => option.label}
                                    getOptionValue={option => option.value}
                                    options={formatDateOptions}
                                    value={formatDate}
                                    onChange={e => setFormatDate(e)}
                                    disabled={false}
                                    isSearchable={false}
                                />
                            </Col>
                        </Row>
                    </div>
                    {!textError &&
                        <div className="section-form">
                            <Row className="mb-2">
                                <Col sm={12}>
                                    Merci d'utiliser un fichier Excel conforme au modèle.
                                    Vous pouvez télécharger le <a href="assets/xls/ImportCommandeEntete.xlsx" target="_blank">modèle</a>,
                                    la&nbsp;
                                    <a href="assets/pdf/LOGeCOM - ImportMultiCommandes.pdf" target="_blank">documentation</a>
                                    &nbsp;et un <a href="assets/xls/ImportCommande_Exemple.xlsx" target="_blank">fichier exemple.</a>
                                </Col>
                            </Row>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={!formValid || uploading} variant="default" className="blue" onClick={importer}>Importer</Button>
                    <Button disabled={uploading} variant="default" className="blue" onClick={close}>Fermer</Button>
                </Modal.Footer>
            </Modal>
            <Alerte
                isModalVisible={message}
                handleAlerte={() => setMessage(null)}
                message={message}
            >
            </Alerte>
        </div >
    );
}

export default ImportOrder;